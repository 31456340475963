<template>
     <div class="contract_info">
          <div class="info_main">
               <div class="member_title_breadcrumb">
                    <el-breadcrumb separator-class="el-icon-arrow-right" style="line-height: 40px;font-size: 10px;">
                         <el-breadcrumb-item :to="{path:'/member/inquiry/myCon'}">合约订单</el-breadcrumb-item>
                         <el-breadcrumb-item>合约详情</el-breadcrumb-item>
                    </el-breadcrumb>
               </div>

               <div class="info_z">
                    <div class="outer2 flex_row_between_center" v-if="info.state==10&&ban">
                         <div class="section3">
                              <span class="txt1">接受合约时间还剩下</span>
                              <span class="word2">{{timeStr.day}}</span>
                              <span class="txt1">&nbsp;天</span>
                              <span class="word2">{{timeStr.hour}}</span>
                              <span class="txt1">小时</span>
                              <span class="word2">{{timeStr.minute}}</span>
                              <span class="txt1">&nbsp;分，到期未接受则该合约失效。</span>
                         </div>
                         <div class="section4 flex-col" @click="ban=false">
                              <span class="el-icon-close"></span>
                         </div>
                    </div>

                    <div class="outer3">
                         <div class="outer31 flex-row">
                              <div class="section5">
                                   <span class="word5">合约单号：</span>
                                   <span class="txt4">{{info.contractSn}}</span>
                              </div>
                              <div class="section6">
                                   <span class="word5">合约状态:&nbsp;&nbsp;</span>
                                   <span class="txt5">{{info.stateValue}}</span>
                              </div>
                         </div>
                         <div class="outer31 flex-row">
                              <div class="section5">
                                   <span class="word5">发起时间：</span>
                                   <span class="txt4">{{info.createTime}}</span>
                              </div>
                         </div>
                    </div>

                    <div class="outer4">
                         <div class="outer5 flex-col">
                              <span class="word7">收货地址</span>
                         </div>

                         <div class="section7">
                              <div class="outer6">
                                   <span class="txt6">*</span>
                                   <span class="word8">收货人：&nbsp;</span>
                                   <span class="word9">{{info.receiverName}}</span>
                              </div>
                              <div class="outer6">
                                   <span class="txt6">*</span>
                                   <span class="word8">手机号：&nbsp;</span>
                                   <span class="word9">{{info.receiverMobile}}</span>
                              </div>
                              <div class="outer6">
                                   <span class="txt6">*</span>
                                   <span class="word8">所在地区：&nbsp;</span>
                                   <span class="word9">{{info.receiverAddress}}</span>
                              </div>
                              <div class="outer6">
                                   <span class="txt6">*</span>
                                   <span class="word8">详细地址：&nbsp;</span>
                                   <span class="word9">{{info.receiverAddressDetail}}</span>
                              </div>
                         </div>
                    </div>

                    <div class="outer7">
                         <div class="outer5 flex-col">
                              <span class="word7">产品及费用清单</span>
                         </div>

                         <div class="section8">
                              <el-table :data="info.productList" style="width: 100%">
                                   <el-table-column type="index" width="100" label="序号" align="center" />
                                   <el-table-column width="120" label="产品图片" align="center">
                                        <template #default="scope">
                                             <div class="flex_row_center_center">
                                                  <img class="pic3" :src="scope.row.productImage"
                                                       v-if="scope.row.productImage">
                                                  <img class="pic3" src="@/assets/inquiry/inq_icon4.png" v-else />
                                             </div>
                                        </template>
                                   </el-table-column>
                                   <el-table-column prop="productName" width="180" label="产品名称" align="center" />

                                   <el-table-column prop="spec" width="140" label="规格型号" align="center" />
                                   <el-table-column prop="buyNum" width="120" label="数量" align="center" />
                                   <el-table-column prop="taxPrice" width="110" label="单价(元)" align="center" />
                                   <el-table-column prop="price" width="150" label="未税单价(元)" align="center" />
                                   <el-table-column prop="taxRate" width="120" label="税率" align="center" />
                                   <el-table-column prop="totalAmount" width="117" label="小计" align="center" />
                              </el-table>
                         </div>

                         <div class="section8">
                              <el-table :data="info.feeList" style="width: 100%">
                                   <el-table-column prop="feeName" width="231" label="收费项" align="center" />
                                   <el-table-column prop="feeTax" width="231" label="报价(元)" align="center" />
                                   <el-table-column prop="fee" width="231" label="未税报价(元)" align="center" />
                                   <el-table-column prop="taxRate" width="231" label="税率" align="center" />
                                   <el-table-column prop="feeTax" width="231" label="小计" align="center" />
                              </el-table>
                         </div>

                         <div class="total1 flex_column_center_end">
                              <div class="outer17 flex_row_start_center">
                                   <div class="group3">
                                        <span class="word36">合计金额：</span>
                                        <span class="info15">¥</span>
                                        <span class="info15">{{getPartNumber(info.totalAmount,'int')}}</span>
                                        <span class="word37">{{getPartNumber(info.totalAmount,'decimal')}}</span>
                                   </div>
                                   <span class="txt21">(含税：￥{{info.taxAmount&&info.taxAmount.toFixed(2)}})</span>
                              </div>
                              <div class="outer17 flex_row_start_center">
                                   <div class="group3">
                                        <span class="word36">优惠金额：</span>
                                        <span
                                             class="word36">￥{{info.discountAmount&&info.discountAmount.toFixed(2)}}</span>
                                   </div>
                              </div>
                              <div class="outer17 flex_row_start_center">
                                   <div class="group3">
                                        <span class="word36">合约金额：</span>
                                        <span class="info15">¥</span>
                                        <span class="info15">{{getPartNumber(info.contractAmount,'int')}}</span>
                                        <span class="word37">{{getPartNumber(info.contractAmount,'decimal')}}</span>
                                   </div>
                                   <span class="txt21">(含税：￥{{info.taxAmount&&info.taxAmount.toFixed(2)}})</span>
                              </div>
                         </div>
                    </div>


                    <div class="outer8">
                         <div class="outer5 flex-col">
                              <span class="word7">交易合约信息</span>
                         </div>
                         <div class="section9">
                              <div class="part1 flex_row_start_center">
                                   <span class="info19">合约接受截止时间：</span>
                                   <span class="paragraph3">{{info.endTime}}<br /></span>
                              </div>
                              <div class="part1 flex_row_start_center">
                                   <span class="info19">买方：</span>
                                   <span class="paragraph3">{{info.receiverName}}<br /></span>
                              </div>
                              <div class="part1 flex_row_start_center">
                                   <span class="info19">卖方：</span>
                                   <span
                                        class="paragraph3">{{info.enterpriseName}}&nbsp;&nbsp;({{info.contactName}}&nbsp;{{info.contactMobile}})</span>
                              </div>
                              <div class="part1 flex_row_start_center">
                                   <span class="info19">最晚交付日期：</span>
                                   <span class="paragraph3">{{info.latestDeliverDate}}<br /></span>
                              </div>
                              <div class="part1 flex_row_start_center">
                                   <span class="info19">结算方式：</span>
                                   <span class="paragraph3">{{info.billTypeName}}<br /></span>
                              </div>
                              <div class="part1 flex_row_start_center">
                                   <span class="info19">发票类型：</span>
                                   <span class="paragraph3">{{invoice[info.invoiceType]}}<br /></span>
                              </div>
                              <div class="part1 flex_row_start_center">
                                   <span class="info19">配送方式：</span>
                                   <span class="paragraph3">{{info.deliverTypeName}}<br /></span>
                              </div>
                         </div>
                    </div>

                    <div class="outer9">
                         <div class="outer5 flex-col">
                              <span class="word7">其他约定</span>
                         </div>
                         <div class="flex_row_start_start section10">
                              <span class="txt28">其他约定：</span>
                              <span class="infoBox3">{{info.notes}}</span>
                         </div>
                    </div>


               </div>

               <div class="outer24 flex_row_center_center" v-if="info.state==10">
                    <div class="bd5 flex-col" @click="acceptContract">
                         <span class="word46">接受合约</span>
                    </div>
               </div>
          </div>
     </div>
</template>


<script>
     import { ref, reactive, getCurrentInstance, toRefs, onMounted } from 'vue'
     import { useRouter, useRoute } from 'vue-router'
     import { inqUtils } from '@/utils/inquiry_utils.js'
     import { ElMessage } from 'element-plus'
     export default {
          setup() {
               const { getPartNumber, handleCountDown, timeStr } = inqUtils()
               const { proxy } = getCurrentInstance()
               const route = useRoute()
               const router = useRouter()
               const contract_info = reactive({
                    info: {}
               })
               const ban = ref(true)
               const getConInfo = () => {
                    proxy.$get('v3/business/front/supplierInquire/contractDetail', {
                         contractId: route.query.contractId
                    }).then(res => {
                         if (res.state == 200) {
                              contract_info.info = res.data
                              if (contract_info.info.state == 10) {
                                   let { createTime, endTime } = res.data
                                   handleCountDown(createTime, endTime)
                              }

                         }
                    })
               }

               const acceptContract = () => {

                    proxy.$confirm('确认接受该合约？\n请确认对合约信息无异议后再接受合约。', {
                         confirmButtonText: '接受合约',
                         cancelButtonText: '我再看看',
                         type: 'warning',
                         center: true
                    }).then(res => {
                         proxy.$post('v3/business/front/purchaseInquire/confirmContract', { contractId: contract_info.info.contractId }).then(res => {
                              if (res.state == 200) {
                                   ElMessage.success(res.msg)
                                   setTimeout(() => {
                                        getConInfo()

                                   }, 2000)
                              }
                         })
                    })
               }

               const invoice = reactive({
                    1: '无需发票',
                    2: '增值税专用发票',
                    3: '增值税普通发票',
               })


               onMounted(() => {
                    if (route.query.contractId) {
                         getConInfo()
                    }
               })

               return {
                    ...toRefs(contract_info),
                    getPartNumber,
                    acceptContract,
                    timeStr,
                    ban,
                    invoice
               }
          }
     }
</script>

<style lang="scss" scoped>
     .contract_info {
          background: #F7F7F7;

          .info_main {
               width: 1200px;
               margin: 0 auto;

               .member_title_breadcrumb {
                    padding-left: 10px;
                    background: $colorK;

                    .el-breadcrumb__item {
                         height: 46px;
                         line-height: 46px;
                    }

                    .el-breadcrumb__inner {
                         color: #666666 !important;
                         font-weight: 700 !important;
                    }

                    .is-link {
                         color: #666666 !important;
                         font-weight: 400 !important;

                         &:hover {
                              color: #333333 !important;
                              font-weight: 700 !important;
                         }
                    }
               }


               .info_z {
                    background-color: #fff;
                    padding: 20px;
                    padding-bottom: 57px;

                    .outer2 {
                         background-color: rgba(254, 225, 205, 0.2);
                         border-radius: 6px;
                         height: 40px;
                         border: 1px solid rgba(213, 109, 40, 0.2);

                         .section3 {
                              margin-left: 10px;

                              .txt1 {

                                   color: rgba(102, 102, 102, 1);
                                   font-size: 14px;
                              }

                              .word2 {

                                   color: rgba(251, 109, 14, 1);
                                   font-size: 14px;
                                   line-height: 14px;
                              }
                         }

                         .section4 {
                              width: 14px;
                              height: 14px;
                              margin-right: 10px;
                              font-size: 14px;
                         }

                    }


                    .outer3 {

                         padding: 20px;
                         padding-bottom: 0;

                         .outer31 {

                              margin-bottom: 20px;

                              .section5,
                              .section6 {

                                   .word5 {
                                        color: rgba(102, 102, 102, 1);
                                        font-size: 14px;
                                   }

                                   .txt4 {
                                        color: rgba(51, 51, 51, 1);
                                        font-size: 14px;
                                   }

                                   .txt5 {

                                        color: rgba(40, 95, 222, 1);
                                        font-size: 14px;
                                        line-height: 14px;
                                   }
                              }

                              .section6 {
                                   margin-left: 52px;
                              }
                         }
                    }


                    .outer5 {
                         background-color: rgba(40, 95, 222, 0.1);
                         border-radius: 2px;
                         height: 40px;

                         .word7 {
                              color: rgba(18, 18, 18, 1);
                              font-size: 16px;
                              font-family: SourceHanSansCN-Medium;
                              margin: 12px 0 0 19px;
                         }
                    }

                    .outer4 {
                         .section7 {
                              margin-top: 20px;
                              padding: 0 20px;

                              .outer6 {
                                   margin-bottom: 20px;

                                   .txt6 {
                                        color: rgba(230, 0, 18, 1);
                                        font-size: 14px;
                                        font-family: SourceHanSansCN-Regular;
                                   }

                                   .word8 {
                                        color: rgba(102, 102, 102, 1);
                                        font-size: 14px;
                                   }

                                   .word9 {
                                        color: rgba(51, 51, 51, 1);
                                        font-size: 14px;
                                   }
                              }
                         }
                    }

                    .outer7 {
                         .section8 {
                              margin-top: 19px;
                              border: 1px solid #DCDCDC;

                              .pic3 {
                                   width: 50px;
                                   height: 50px;
                              }
                         }
                    }

                    .total1 {
                         margin-top: 26px;

                         .outer17 {
                              margin-bottom: 18px;

                              .group3 {

                                   .word36 {
                                        color: rgba(51, 51, 51, 1);
                                        font-size: 14px;
                                   }

                                   .info15 {
                                        color: rgba(243, 6, 6, 1);
                                        font-size: 16px;
                                   }

                                   .word37 {
                                        color: rgba(243, 6, 6, 1);
                                        font-size: 14px;
                                   }
                              }

                              .txt21 {
                                   color: rgba(51, 51, 51, 1);
                                   font-size: 14px;
                                   margin-left: 20px;
                              }
                         }
                    }

                    .outer8 {
                         margin-top: 20px;

                         .section9 {
                              padding: 20px;

                              .part1 {
                                   margin-bottom: 20px;

                                   &:last-child {
                                        margin-bottom: 0;
                                   }

                                   .info19 {
                                        color: rgba(102, 102, 102, 1);
                                        font-size: 14px;
                                   }

                                   .paragraph3 {
                                        color: rgba(51, 51, 51, 1);
                                        font-size: 14px;
                                   }
                              }
                         }


                    }


                    .outer9 {
                         .section10 {
                              margin-top: 19px;
                              padding: 0 20px;

                              .txt28 {
                                   color: rgba(102, 102, 102, 1);
                                   font-size: 14px;
                                   line-height: 23px;
                                   white-space: nowrap;
                              }

                              .infoBox3 {
                                   color: rgba(51, 51, 51, 1);
                                   font-size: 14px;
                                   text-align: left;
                                   line-height: 23px;
                                   word-break: break-all;
                              }
                         }
                    }






               }

               .outer24 {
                    box-shadow: 0px 2px 10px 0px rgba(131, 131, 131, 0.3);
                    background-color: rgba(255, 255, 255, 1);
                    height: 79px;

                    .bd5 {
                         background-color: rgba(40, 95, 222, 1);
                         border-radius: 4px;
                         height: 36px;
                         width: 154px;
                         cursor: pointer;

                         .word46 {
                              color: rgba(255, 255, 255, 1);
                              font-size: 16px;
                              display: block;
                              margin: 9px 0 0 44px;
                         }
                    }
               }
          }
     }
</style>